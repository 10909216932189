<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :search="true" />

    <no-records-found
      v-if="showNotFound"
      text="No clients found in the system."
    />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="6" xl="8">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mr-50 mt-2"
              v-b-toggle.sidebar-add-client
            >
              <feather-icon icon="UserPlusIcon" class="mr-50" />
              <span class="align-middle">Add Client</span>
            </b-button>
          </div>
        </b-col>
        <b-col md="6" xl="4" class="mt-2">
          <!-- basic -->
          <b-form-group>
            <b-input-group>
              <b-form-input
                v-model.trim="search"
                placeholder="Search for Clients"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="clientRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="clients"
        :fields="fields"
        ref="clientsTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="row">
          <span> {{ row.item.first_name }} {{ row.item.last_name }}</span>
        </template>

        <template #cell(phone)="row">
          <span> {{ row.item.code }}{{ row.item.phone }}</span>
        </template>

        <template #cell(balance)="row">
          <span>KSH {{ row.item.balance }}</span>
        </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'pending'"
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'active'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'suspended'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'unverified'"
            variant="outline-warning"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(action)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            :to="{ name: 'client-manage', params: {clientId: data.item.ID } }"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
    
    <sidebar-add-client @client-refresh="clientRefresh" />
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BAvatar,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SidebarAddClient from "./components/SidebarAddClient.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";


export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BAvatar,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    SidebarAddClient,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {

    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "name",
          label: "NAME",
        },
        {
          key: "phone",
          label: "PHONE",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "balance",
          label: "BALANCE",
        },
        {
          key: "format_created_at",
          label: "Registered At",
        },
        {
          key: "action",
          label: "ACTION",
        }
      ],
      search: "",
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    clients(ctx, callback) {
      let params;
      if (this.search) {
        params = "?page=" + ctx.currentPage + "&search=" + this.search;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      this.$http
        .get("/clients/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    clientRefresh(){
      this.$refs.clientsTable.refresh();
    },
  },
};
</script>

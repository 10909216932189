<template>
  <b-sidebar
    id="sidebar-add-client"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add Client</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="addClientFormValidator" #default="{ invalid }">
        <b-form class="p-2" @submit.prevent="addClient" ref="addClientForm">
          <!-- first name -->
          <b-form-group label-for="firstName" label="First Name">
            <validation-provider
              #default="{ errors }"
              name="First Name"
              vid="firstName"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="firstName"
                name="firstName"
                placeholder="John"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group label-for="lastName" label="Last Name">
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              vid="lastName"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="lastName"
                name="lastName"
                placeholder="Doe"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group label="Email" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="email"
                name="email"
                placeholder="john@example.com"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- phone number -->

          <b-form-group label="Phone Number" label-for="phone">
            <b-input-group>
              <v-select
                v-model="country"
                :options="countries"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(country) => country.value"
                class="col-5 col-g-0 pl-0"
              />
              <validation-provider
                #default="{ errors }"
                name="Phone"
                vid="phone"
                rules="required|integer"
                class="col-7 col-g-0"
              >
                <b-form-input
                  id="phone"
                  type="tel"
                  v-model="phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="XXXXXXXXX"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="invalidPhone"
                  >Invalid Phone Number</small
                >
              </validation-provider>
            </b-input-group>
          </b-form-group>

          <!-- Website -->
          <b-form-group label="Website" label-for="website">
            <b-form-input
              id="website"
              v-model="website"
              name="website"
              placeholder="https://www.john.com"
            />
          </b-form-group>

          <!-- password -->
          <b-form-group label="Password" label-for="password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  :type="password1FieldType"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password1ToggleIcon"
                    @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Individual or An Organization" class="mb-2">
            <b-row no-gutters>
              <b-form-radio
                v-model="isCompany"
                name="company"
                :value="false"
                class="col"
              >
                Individual
              </b-form-radio>

              <b-form-radio
                v-model="isCompany"
                name="company"
                :value="true"
                class="col"
              >
                Organization
              </b-form-radio>
            </b-row>
          </b-form-group>

          <b-form-group class="mb-2">
            <b-form-checkbox
              id="sendEmail"
              v-model="sendEmail"
              name="sendEmail"
              value="true"
              unchecked-value="false"
            >
              Send email to client with account credentials
            </b-form-checkbox>
          </b-form-group>
          <!-- Form Actions -->

          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="invalid || invalidPhone || formIsLoading"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp; Submit
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BSidebar,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required, email, integer } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BSpinner,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  emits: ["client-refresh"],
  mixins: [togglePasswordVisibility],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      cPassword: "",
      phone: "",
      website: "",
      isCompany: false,
      country: null,
      sendEmail: null,
      // validation
      required,
      email,
      integer,
      countries: [],
      formIsLoading: false,

      // Toggle Password
      password1FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    invalidPhone() {
      if (this.phone == "" || this.phone == null) {
        return false;
      }
      let phoneSanitize = this.phone;
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if (phoneSanitize.substr(0, 1) == "+") {
        phoneSanitize = phoneSanitize.substr(1);
      }
      if (phoneSanitize.substr(0, 2) == "00") {
        phoneSanitize = phoneSanitize.substr(2);
      }
      if (phoneSanitize.substr(0, 1) == "0") {
        phoneSanitize = phoneSanitize.substr(1);
      }
      if (phoneSanitize.substr(0, 3) == "254") {
        phoneSanitize = phoneSanitize.substr(3);
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    addClient() {
      this.formIsLoading = true;
      this.$refs.addClientFormValidator.validate().then(success => {
        if (success) {
          this.$http.post('/clients/create',{
              first_name:this.firstName,
              last_name:this.lastName,
              email:this.email,
              password:this.password,
              phone:this.phone,
              website:this.website,
              is_company:this.isCompany,
              country_id:this.country,
              send_email: this.sendEmail
            })
            .then(response => {
              this.formIsLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("client-refresh");
              this.$root.$emit("bv::toggle::collapse", "sidebar-add-client");
              this.$nextTick(() => {
                this.$refs.addClientFormValidator.reset();
              });
              this.$refs.addClientForm.reset();
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      });
    },
  },
  created(){
    this.$http.get('/countries/active')
    .then(response => {
      for (const country of response.data.data) {
        let pushCountry = {
          value : country.ID,
          // text: country.code,
          text: country.iso + " (" + country.code + ")",
        }
        this.countries.push(pushCountry);
        if (country.title = "Kenya"){
          this.country = country.ID;
        }
      }
    })
    .catch(error => {
      for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
    })
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  padding-left: 0px !important;
  .vs__actions {
    display: none !important;
  }
}
.col-g-0 {
  padding-right: 0;
}
</style>